<template>
  <Layout>
    <div class="visit-room">
      <span v-if="error" class="visit-room-err">
        {{ error }}
      </span>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <div v-if="encounter">
        <div class="visit-room-header">
          <v-chip v-if="roomIsStarted && !roomIsFinished && timer" color="red darken-1" dark label>
            {{ timer }}
          </v-chip>
          <span class="visit-room-header-select">
            {{ $t("visits.visitRoomWith") }} {{ encounter.practitioner.name }}
          </span>
          <div class="visit-room-header-bordered">
            <v-icon color="icon">mdi-calendar-blank</v-icon>
            <p>{{ dateFormated }}</p>
          </div>
          <div class="visit-room-header-bordered">
            <v-icon color="icon">mdi-clock-outline</v-icon>
            <p>{{ timeRange }}</p>
          </div>
        </div>
        <v-row class="visit-room-content" no-gutters>
          <VideoRoom v-if="!roomIsFinished && room" :call-time="timer" />
          <div v-if="room && roomIsFinished" class="visit-room-err">
            <img class="visit-room-err_img" src="@/assets/chat/registration_congratulations.svg" />
            <span class="visit-room-err_text"> Log Into your Account. </span>
            <span class="visit-room-err_text_small"> You need to Log In to access this page. </span>
            <v-btn class="visit-room-err_btn" color="primary" outlined @click="gotoLogin"> Log In</v-btn>
          </div>
        </v-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Layout from "@/components/Layout";
import VideoRoom from "@/components/VideoRoom";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { visitRoomMixin } from "@/mixins/visitRoom";
import { useAuthStore } from "@/pinia-store/auth";
import { useTwoFactorStore } from "@/pinia-store/twoFactor";

export default {
  components: {
    Layout,
    VideoRoom,
  },
  mixins: [visitRoomMixin],
  computed: {
    ...mapState(useTwoFactorStore, ["getSucces"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["getCustomTokenRoom", "signInWithCustomToken", "refreshToken"]),
    async fetchRoom() {
      this.error = null;
      const roomId = this.$route.params.roomId || this.$route.params.encounterId;

      const { inviteId, password } = this.$route.query;
      if (!inviteId || !password || !roomId) {
        const err = this.$t("visits.errors.linkError");
        snackBarEventBus.$emit(snackBarEventName, { message: err, type: "error" });
        this.error = err;
        return;
      }
      this.loading = true;
      try {
        await this.getCustomTokenRoom({ inviteId, password });
        await this.signInWithCustomToken();

        // let token = await this.getSucces;
        // await this.refreshToken(token.authorized.authorized.idToken);

        await this.clear();
        this.encounter = await this.getEncounter(roomId);
      } catch (e) {
        console.error(e);
        this.error = e.message || e;
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    gotoLogin() {
      this.$router.push("/auth/signin");
    },
  },
};
</script>

<style lang="scss" scoped>
.visit-room-err {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_img {
    margin-bottom: 2em;
  }

  &_text {
    margin-bottom: 0.3em;
    font-size: 28px;
    font-weight: 500;
    color: #33343e;
  }

  &_text_small {
    margin-bottom: 2em;
    font-size: 14px;
    font-weight: 400;
    color: #91909c;
  }

  &_btn {
    letter-spacing: normal;
    text-transform: initial;
  }
}
</style>
